import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getTranslations } from '@/utils'

export const getNews = (data: any) =>
  request({
    method: 'post',
    url: '/api/news/getNews',
    data
  })

export const getNewsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/news/getNewsCount',
    data
  })

export const getNew = (params: any) =>
  request({
    method: 'get',
    url: '/api/news/getNew',
    params
  })

export const saveNews = (data: any) =>
  request({
    method: 'post',
    url: '/api/news/saveNews',
    data
  })

export const deleteNew = (params: any) =>
  request({
    method: 'get',
    url: '/api/news/deleteNews',
    params
  })

export const deleteNewBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/news/deleteNewBulk',
    data
  })
