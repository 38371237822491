









































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  getNew,
  saveNews
} from '@/api/news'
import Sticky from '@/components/Sticky/index.vue'
import { AppModule, DeviceType } from '@/store/modules/app'
import { errorMsg, locales, successMsg } from '@/utils'

@Component({
  name: 'NewsDetail',
  components: {
    Sticky
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean

  private loading = false
  private skeletonLoader = false
  private languages = locales
  private newsItem: any = {}

  created() {
    if (this.isEdit) {
      this.getItem(this.$route.params.id)
    } else {
      this.newsItem.news_date = new Date().getTime()
      this.newsItem.locale = 'el'
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async getItem(id: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getNew({ id })
      this.newsItem = data
    } catch (err) {}
    this.skeletonLoader = false
  }

  private async saveItem() {
    this.loading = true
    try {
      const result:any = await saveNews(this.newsItem)
      await successMsg('actions.apiSuccessSave')
      if (!this.isEdit) {
        return this.$router.push('/news/edit/' + result.data.id)
      }
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.loading = false
  }
}
