




import { Component, Vue } from 'vue-property-decorator'
import NewsDetail from './components/NewsDetail.vue'

@Component({
  name: 'EditNews',
  components: {
    NewsDetail
  }
})
export default class extends Vue {}
